.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.gradientBody{
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to bottom right, rgba(240, 147, 251, 1), rgba(245, 87, 108, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to bottom right, rgba(240, 147, 251, 1), rgba(245, 87, 108, 1))
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: clip;
  position: relative;
}

.wrapper-child{
  height: 100vh;
  width: 100%;
  object-fit: contain;
}

.wrapper-no-scroll{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: clip;
}

.center-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: clip;
}

.form-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px #555;
  background-color: #ffffff;
}

.videoWrapper {
  width:100vw;
  height:100vh;
  background-color: #F0F;
  position: relative;
}

.videoWrapper .contain {
  /*object-fit: contain;*/
/*       object-fit: fill; */
  object-fit: contain;
  width:100%;
  height:100%;
}

.videoWrapper .fill {
  /*object-fit: contain;*/
/*       object-fit: fill; */
  object-fit: fill;
  width:100%;
  height:100%;
}



.sniper{
  width: 50%;
  height: 50%;
  animation: movementFadeIn 5s linear forwards;
  visibility: hidden;
  animation-delay: 11s;
}

.peeped-map-button {
  position: absolute;
  animation: wiggle 2s linear infinite;
  width: 40vw;
  bottom: 20vh;
  z-index: 20;
}

.videoOverlay {
  position: absolute;
  text-align: center;
  color: #f1f1f1;
  z-index: 2;
  overflow: clip;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}




#videoOverlayLarge {
  position: relative;
  text-align: center;
  color: #f1f1f1;
  z-index: 2;
  font-size: 11vw;
  overflow: clip;
  margin-top: 12vh;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 rgb(56, 52, 52), -1px 0 0 #000;
}

#videoOverlaySmall {
  display: inline-block;
  position: relative;
  color: #ffff00;
  z-index: 2;
  font-size: 3vw;
  overflow: clip;
  top: -9vh;
  left: 19vw;
  width: 40vw;
  animation: movement  ease-in-out 2s infinite alternate;
  text-align:center;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

#funVideoOverlaySmall {
  display: inline-block;
  position: relative;
  z-index: 2;
  font-size: 3vw;
  overflow: clip;
  top: -9vh;
  left: 19vw;
  width: 40vw;
  animation: funMovement ease-in-out 2s infinite alternate;
  text-align:center;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

#ipDoxing {
  position: absolute;
  color: #ffff00;
  z-index: 2;
  font-size: 2.5vw;
  margin-left: auto; 
  margin-top: 7.5em;
  transform: scale(0);
  animation: movementFadeIn 5s linear forwards;
  animation-delay: 11s;
  text-align: center;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

@keyframes fadeIn {
  0% {visibility: hidden;
    opacity: 0;}
  100%   {visibility: visible;
    opacity: 1;}
}

@keyframes movementFadeIn {
  0% { transform: scale(0);
    visibility: hidden;
    opacity: 0;}
  100%   {transform: scale(2);
    visibility: visible;
    opacity: 1;}
}

@keyframes movement {
  0% { transform : scale(.8) rotate(-9deg); }
  100%   { transform : scale(.7) rotate(-8deg);}
}

@keyframes funMovement {
  0% { color: red; transform : scale(1.2) rotate(-9deg);}
  14% { color: orange;}
  28% { color: yellow;}
  42% { color: green;}
  56% { color: blue;}
  70% { color: indigo;}
  84% { color: violet;}
  100% { color: red; transform : scale(.7) rotate(-8deg);}
}



.dota-body{
  background: linear-gradient(90deg, rgba(201,200,215,1) 0%, rgb(100, 100, 102) 75%);
  width: 100%;
  height: 100vh;
}

.ip-span{
  animation: wiggle 2s linear infinite;
  animation-delay: 11s;
  display:inline-block
}

.wiggle-effect{
  animation: wiggle 2s linear infinite;
}

.dota-center{
  text-align: center;
  top: 35%;
  position: relative;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

.sun {
  position:fixed;
  background-color: yellow;
  border-radius: 50%;
  height: 50vh;
  aspect-ratio: 1 / 1;
  animation: 15s linear 0s infinite alternate sunRiseMain;
}

/* it is possible to animate multiple properties in a single animation */
@keyframes sunRiseMain {
  from {
    transform: translateY(75vh);
    background-color: rgb(237, 113, 56);
    filter: brightness(75%);
  }
  to {
    transform: translateY(45vh);
    background-color: yellow;
    /* unset properties i.e. 'filter' will revert to default values */
  }
}

.sunBackground {
	background: linear-gradient(180deg, #722aca, #658ec4, #d6e490);
	background-size: 400% 400%;
	animation: gradient 12s ease infinite alternate;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 50%;
	}
	100% {
		background-position: 50% 100%;
	}
}

.fade-enter .fader {
  opacity: 0;
  transform: translateX(45%);
}
.fade-enter-active .fader {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .fader {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .fader {
  opacity: 0;
  transform: translateX(-45%);
}
.fade-enter-active .fader,
.fade-exit-active .fader {
  transition: opacity 500ms, transform 500ms;
}

.shrink-enter .shrinker {
  opacity: 0;
  transform: scale(0);
}
.shrink-enter-active .shrinker {
  opacity: 1;
  transform: scale(2);
}
.shrink-exit .shrinker {
  opacity: 1;
  transform: scale(1);
}
.shrink-exit-active .shrinker {
  opacity: 0;
  transform: scale(0);
}
.shrink-enter-active .shrinker,
.shrink-exit-active .shrinker {
  transition: opacity 1s, transform 1s;
}

.about {
  background: #fff;
  width: 24em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: clip;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.about:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.about img {
  object-fit:fill;
}

.about p {
  color: #777;
}

.about h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.about-body {
  padding: 1.2em;
}

.cardWrapper {
  background: hsla(210, 20%, 90%, 1);
  padding: 2em 0;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  flex-grow: 1;
}

.col-md-4 {
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

.suggestionHeader {
  position: absolute;
  color: #f1f1f1;
  z-index: 2;
  font-size: 4.5em;
  overflow: clip;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top:1.25em;
  text-align:center;
  width: 80vw;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}

.suggestionForm {
  position: absolute;
  z-index: 2;
  font-size: 1.5em;
  overflow: clip;
  left: 0; 
  right: 0;
  margin-left: auto; 
  margin-right: auto;
  width: 50%;
  top: 45%;
  align-items: center;
  flex-direction: column;
}

.suggestionForm Button {
  opacity: .8;
}

.suggestionForm textarea {
  opacity: .4;
}

.fun{
  animation: rainbow ease-in-out 2s infinite alternate;
}

.plot {
  background: #fff;
  width: 24em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: clip;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

@keyframes rainbow {
  0% { color: red;}
  14% { color: orange;}
  28% { color: yellow;}
  42% { color: green;}
  56% { color: blue;}
  70% { color: indigo;}
  84% { color: violet;}
  100% { color: red;}
}

.warning {
  background-color: #000;
  color: red;
  display: flex;
  font-size: 10em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  height: 100vh;
}

.stickyButtons{
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
  margin-left: 1vw;
  
}

.ashTrayContainer{
    
    background-color: #DBE0CD;
    justify-content: center;
    align-content: center;
    width: 100vw;
    margin-top: -15vh;
}

.ashTrayInstruction{
  width: 55vw;
  margin-top: 3em;
  position: flex;
  margin-left: auto;
  margin-right: auto;
}

.ashTrayInstruction img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.navBar{
  vertical-align: 'top';
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top:0;
}

#dotaBody{
  background-image: url('images/minion.gif');
}

.dotaFlashing{
  animation: blackwhite ease-in-out 1s infinite alternate;
  text-align: center;
}

.dotaContainer {
  position: relative;
  text-align: center;
  color: white;
}

.dotaCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 60px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  overflow-wrap: break-word;
}

.dotaForm {
  font-size: 1.5em;
  left: 0; 
  right: 0;
  margin-left: auto; 
  margin-right: auto;
  width: 15em;
  align-items: center;
  flex-direction: column;
}

.zoom{
  animation: leftrightfast ease-in-out 2s infinite alternate,
             spin linear 3s infinite, 
             depth linear 3s infinite,
             rainbow ease-in-out 2s infinite alternate;
  color: white;
  background: maroon;
  text-align:center;
  width:100px;
  margin: auto;
}